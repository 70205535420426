// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-about-js": () => import("./../../../src/templates/page-about.js" /* webpackChunkName: "component---src-templates-page-about-js" */),
  "component---src-templates-page-default-js": () => import("./../../../src/templates/page-default.js" /* webpackChunkName: "component---src-templates-page-default-js" */),
  "component---src-templates-page-homepage-js": () => import("./../../../src/templates/page-homepage.js" /* webpackChunkName: "component---src-templates-page-homepage-js" */),
  "component---src-templates-project-type-js": () => import("./../../../src/templates/project-type.js" /* webpackChunkName: "component---src-templates-project-type-js" */)
}

